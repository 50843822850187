<template>
  <div>
    <b-form-group
      label="Adı"
      label-for="name"
    >
      <validation-provider
        #default="{ errors }"
        name="Adı"
        rules="required"
      >
        <b-form-input
          id="name"
          v-model="dataItem.name"
          :state="errors.length > 0 ? false:null"
          name="name"
          placeholder="Giriniz.."
          @input="fullNameInput"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>
<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue'
import { localize, ValidationProvider } from 'vee-validate'

export default {
  name: 'SwoNameCard',
  components: { BFormInput, ValidationProvider, BFormGroup },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['swoWorkers/dataItem']
    },
  },
  created() {
    localize(this.locale)
  },
  methods: {
    fullNameInput() {
      this.dataItem.fullname = `${this.dataItem.name} ${this.dataItem.surname}`
    },
  },
}
</script>
