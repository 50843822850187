<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="3"
      >
        <swo-name-card />
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <swo-surname-card />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <swo-subtitle-card />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <swo-sections-card />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <swo-location-card />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import SwoNameCard from '@/views/Service_work_orders/Defines/Workers/Elements/Name.vue'
import SwoSurnameCard from '@/views/Service_work_orders/Defines/Workers/Elements/Surname.vue'
import SwoSubtitleCard from '@/views/Service_work_orders/Defines/Workers/Elements/Subtitle.vue'
import SwoSectionsCard from '@/views/Service_work_orders/Defines/Workers/Elements/WorkerSection.vue'
import SwoLocationCard from '@/views/Service_work_orders/Defines/Workers/Elements/Location.vue'

export default {
  name: 'WorkerDefinitionForm',
  components: {
    SwoLocationCard,
    SwoSectionsCard,
    SwoSubtitleCard,
    SwoSurnameCard,
    SwoNameCard,
    BRow,
    BCol,
  },
}
</script>
